import {
	CloudinaryImage,
	ICloudinaryImageProps
} from 'litto-lib/components/common';
import { useMediaQuery } from 'litto-lib/hooks';
import Link from 'next/link';
import React from 'react';

export type ISelectedAccommodationCardProps = {
	className?: string;
	data: any;
} & Pick<ICloudinaryImageProps, 'cloudinaryOptions' | 'sizes'>;

export const SelectedAccommodationCard: React.FC<
	ISelectedAccommodationCardProps
> = ({ className, data, cloudinaryOptions, sizes }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<Link
			href={
				typeof window !== 'undefined' && window?.location?.search
					? `/accommodations/${data.code}/${window?.location?.search}`
					: `/accommodations/${data.code}`
			}
		>
			<a
				target={`${isMobile ? '_self' : '_blank'}`}
				className={`group flex overflow-hidden rounded no-underline ${
					className || ''
				}`}
			>
				<picture className="aspect-w-3 aspect-h-1 relative w-1/3">
					<CloudinaryImage
						className="transition-transform group-hover:scale-110"
						layout="fill"
						objectFit="cover"
						src={data?.mainImage?.imageUrl}
						alt={data?.translation?.name}
						cloudinaryOptions={cloudinaryOptions}
						sizes={sizes}
					/>
				</picture>
				<div className="flex w-2/3 flex-col p-4">
					<h3 className="font-body text-base font-bold line-clamp-2 md:text-md">
						{data?.translation.name}
					</h3>

					<p className="mt-auto text-sm text-gray-500">{`${data?.numberOfGuests} guests - ${data?.numberOfBedrooms} bedrooms`}</p>
					{data?.averageNightlyPrice && (
						<p className="mt-auto text-sm text-gray-500">{`${
							data?.averageNightlyPrice / 100
						}€/night`}</p>
					)}
				</div>
			</a>
		</Link>
	);
};
