import { AvailableSvg, isEmpty, slugToSvg, slugToWords } from 'litto-lib/utils';
import React from 'react';

import { FeatureItem } from 'components/shared/FeatureItem';
import { IAccommodationAmenity } from 'services/shop/accommodation/interfaces';
export interface IAmenityListProps {
	amenities: { [key: string]: IAccommodationAmenity[] };
	showTitles?: boolean;
	numOfColumns?: 1 | 2;
}

export const AmenityList: React.FC<IAmenityListProps> = ({
	amenities,
	showTitles = true,
	numOfColumns = 1
}) => {
	const amenitiesList = React.useMemo(() => {
		return Object.fromEntries(
			Object.entries(amenities).filter(
				([, v]) => !isEmpty(v) && v.filter(amenity => amenity.value === true)
			)
		);
	}, [amenities]);
	return (
		<>
			{Object.keys(amenitiesList).map(key => {
				return (
					<section key={key} className="mt-14 first:mt-0">
						{showTitles && (
							<h3 className="mb-6 text-lg font-bold">{slugToWords(key)}</h3>
						)}
						<div className={numOfColumns == 2 ? 'md:columns-2' : ''}>
							{amenities[key].map(amenity => {
								const Svg = slugToSvg(amenity.code as AvailableSvg);
								return (
									<FeatureItem
										key={amenity.code}
										withBg
										icon={<Svg />}
										text={amenity.name}
										description={amenity.description || undefined}
										className="mb-5 last:mb-0"
									/>
								);
							})}
						</div>
					</section>
				);
			})}
		</>
	);
};
