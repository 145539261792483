import React from 'react';

import { AccommodationInfo } from '..';
import { PersonDisplay } from 'components/person';
import { SingleHero } from 'components/shared';
import { IAccommodation } from 'services/shop/accommodation/interfaces';

export const AccommodationHero: React.FC<{ accommodation: IAccommodation }> = ({
	accommodation
}) => {
	const accommodationInfoMapped = [
		{
			name: 'Number of bedrooms',
			code: 'number_of_bedrooms',
			value: accommodation.numberOfBedrooms
		},
		{
			name: 'Number of beds',
			code: 'number_of_beds',
			value: accommodation.numberOfBeds
		},
		{
			name: 'Number of guests',
			code: 'number_of_guests',
			value: accommodation.numberOfGuests
		},
		{
			name: 'Number of baths',
			code: 'number_of_baths',
			value: accommodation.numberOfBaths
		}
	];

	return (
		<SingleHero
			title={accommodation.translation.name}
			images={[accommodation.mainImage, ...accommodation.images]}
			contentTop={
				<div className="mb-5 flex items-center justify-between">
					<AccommodationInfo
						className="w-full"
						accommodationInfo={accommodationInfoMapped}
					/>
				</div>
			}
			contentBottom={
				<>
					<div
						className="sm:my-4"
						dangerouslySetInnerHTML={{
							__html: accommodation.translation.shortDescription
						}}
					/>
					<PersonDisplay
						name={`${accommodation.owner.firstName} ${accommodation.owner.lastName}`}
						imgSrc={accommodation.owner.avatar?.avatarUrl}
						className="order-first mt-5 flex-none items-center pr-10"
						languages={[
							...(accommodation?.primaryLanguage
								? [accommodation?.primaryLanguage]
								: []),
							...accommodation?.secondaryLanguages
						]}
					/>
				</>
			}
		/>
	);
};
