import React from 'react'
interface IAccommodationObject {
	code: string;
	name: string;
	value: number;
}
interface IAccommodationInfoProps {
	accommodationInfo: IAccommodationObject[];
	className?: string;
}

export const AccommodationInfo: React.FC<IAccommodationInfoProps> = ({
	accommodationInfo,
	className = ''
}) => (
	<ul className={`${className} flex list-none flex-wrap`}>
		{accommodationInfo.map(item => {
			return (
				<li
					key={item.code}
					className="split-with-dots mr-1 text-base last:mr-0 md:font-extrabold"
				>
					{item.value} {item.name.replace('Number of', '')}
				</li>
			);
		})}
	</ul>
);
