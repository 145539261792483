import { priceParser } from 'litto-lib/helpers';
import { useMediaQuery } from 'litto-lib/hooks';
import { getImageUrl } from 'litto-lib/utils';
import React from 'react';

import {
	Card,
	ICardProps,
	ISkeletonCardProps,
	SkeletonCard
} from 'components/shared';
import { IAccommodationRead } from 'services/shop/accommodation/interfaces';


export type IaccommodationCardProps = {
	product: IAccommodationRead;
	className?: string;
	truncate?: number;
	withDescription?: boolean;
	onClick?: () => void;
	numberOfNights?: number;
} & Omit<ICardProps, 'imgSrc' | 'title'>;

export const AccommodationCard: React.FC<IaccommodationCardProps> = ({
	product,
	className,
	truncate = '2',
	withDescription = false,
	onClick,
	orientation,
	numberOfNights,
	...cardProps
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	const { code, numberOfGuests, numberOfBedrooms, mainImage } = product;
	const { imagePath } = mainImage;
	const [itemHref, setItemHref] = React.useState(`/accommodations/${code}`);

	React.useEffect(() => {
		setItemHref(itemHref + window?.location?.search);
	}, []);

	return (
		<Card
			orientation={orientation}
			className={className || ''}
			meta={`${numberOfGuests} guests - ${numberOfBedrooms} bedrooms`}
			title={product.translation.name}
			info={
				withDescription ? (
					<p className={` ${truncate ? `line-clamp-${truncate}` : ''} `}>
						{product.translation.shortDescription}
					</p>
				) : (
					<></>
				)
			}
			imgSrc={getImageUrl(imagePath)}
			{...(onClick && { onClick })}
			href={itemHref}
			target={`${isMobile ? '_self' : '_blank'}`}
			slotBottom={
				product.averageNightlyPrice ? (
					<>
						<p className="text-gray-500">
							<strong>{`${priceParser(product.averageNightlyPrice)}`}</strong> /
							night
						</p>

						{numberOfNights && (
							<p className="mt-1 underline">
								{priceParser(product.averageNightlyPrice * numberOfNights)}{' '}
								total
							</p>
						)}
					</>
				) : (
					<></>
				)
			}
			sizes="(min-width: 1200px) 25vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
			{...cardProps}
		/>
	);
};

export const AccommodationCardSkeleton: React.FC<
	ISkeletonCardProps
> = props => <SkeletonCard aspectRatio="9x5" {...props} />;
