import {
	GoogleMap,
	GoogleMapProps,
	LoadScriptNext,
	OverlayView
} from '@react-google-maps/api';
import { isEmpty } from 'litto-lib/utils';
import React from 'react';

import { CustomMapMarker } from '.';
import { SelectedAccommodationCard } from 'components/accommodation';
import { IAccommodationRead } from 'services/shop/accommodation/interfaces';


const mapStyles = [
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#686868'
			}
		]
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				color: '#f2f2f2'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100
			},
			{
				lightness: 45
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '-22'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				saturation: '11'
			},
			{
				lightness: '-51'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text',
		stylers: [
			{
				saturation: '3'
			},
			{
				lightness: '-56'
			},
			{
				weight: '2.20'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.fill',
		stylers: [
			{
				lightness: '-52'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				weight: '6.13'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.icon',
		stylers: [
			{
				lightness: '-10'
			},
			{
				gamma: '0.94'
			},
			{
				weight: '1.24'
			},
			{
				saturation: '-100'
			},
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '-16'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				saturation: '-41'
			},
			{
				lightness: '-41'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				weight: '5.46'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.fill',
		stylers: [
			{
				weight: '0.72'
			},
			{
				lightness: '-16'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [
			{
				lightness: '-37'
			}
		]
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#b7e4f4'
			},
			{
				visibility: 'on'
			}
		]
	}
];

const mapOptions:
	| GoogleMapProps['options']
	| any /* For some reason styles is not recognised in type */ = {
	styles: mapStyles,
	fullscreenControl: false,
	streetViewControl: false,
	mapTypeControl: false,
	gestureHandling: 'greedy',
	clickableIcons: false,
	minZoom: 7,
	maxZoom: 18 // NPR. AKO JE JEDAN APARTMAN U BASKOJ VODI ONDA ZOOMIRA PREVISE
};
export interface IAccommodationsMapProps {
	markers?: IAccommodationRead[];
	handleMapDragAndZoom: (geolocation: string, zoom: number) => void;
	hoveredAccommodation?: string;
	initialGeolocationCoordinates?: string;
	initialPage?: number;
	initialZoom?: number;
	forceMapFitBounds?: boolean;
	reverseForceMapFitBounds: () => void;
	filterHeaderHeight: number;
	className?: string;
}

const AccommodationsMap: React.FC<IAccommodationsMapProps> = ({
	markers,
	handleMapDragAndZoom,
	hoveredAccommodation,
	initialGeolocationCoordinates,
	initialPage,
	initialZoom,
	forceMapFitBounds, // flag to force map to adjust boundaries when channel or page is changed
	reverseForceMapFitBounds, // function to reverse flag from inside of the map component
	filterHeaderHeight,
	className
}) => {
	const mapRef = React.useRef<GoogleMap['state']['map'] | null>();

	const [isLoaded, setIsLoaded] = React.useState(false); // map must be loaded to use window.google (in useEffect)

	const skipSync = React.useRef(false); // when markers are loaded, map is set to adjus boundaries to fit them all. By adjusting, new map's method onIdle is triggered, so this flag disable that second fetch
	const isFirstLoad = React.useRef(true); // adjsting map boundaries is only enabled on first load

	const [clickedAccommodation, setClickedAccommodation] = React.useState('');

	const displayedAccommodation = React.useCallback(() => {
		if (!clickedAccommodation) {
			return;
		}

		const accommodation = markers?.find(
			acc => acc?.code === clickedAccommodation
		);

		return (
			<div
				className={`absolute left-1/2 w-11/12 max-w-sm cursor-default rounded bg-white shadow-lg md:w-80`}
				style={{
					top: filterHeaderHeight + 20,
					transform: `translateX(-50%)`
				}}
			>
				<SelectedAccommodationCard className="w-full" data={accommodation} />
			</div>
		);
	}, [markers, clickedAccommodation]);

	let mapUpdater: NodeJS.Timeout;

	const handleOnIdle = () => {
		if (skipSync?.current) {
			skipSync.current = false;
			return;
		}

		mapAndListSyncListener();
	};

	const mapAndListSyncListener = React.useCallback(() => {
		const bounds = mapRef.current?.getBounds();

		if (bounds) {
			handleMapDragAndZoom(
				bounds?.toUrlValue(),
				mapRef.current?.getZoom() || 10
			);
		}
	}, [handleMapDragAndZoom]);

	const onMapLoad = React.useCallback((map: any) => {
		mapRef.current = map;
		setIsLoaded(true);
	}, []);

	React.useEffect(() => {
		if (
			window.google &&
			isLoaded &&
			markers &&
			(isFirstLoad.current || forceMapFitBounds)
		) {
			skipSync.current = true;
			if (initialGeolocationCoordinates && initialPage === 1 && initialZoom) {
				const initialCoordinates = initialGeolocationCoordinates
					.split(',')
					.map(coordinate => parseFloat(coordinate));
				mapRef?.current?.setCenter({
					lat: (initialCoordinates?.[0] + initialCoordinates?.[2]) / 2,
					lng: (initialCoordinates?.[1] + initialCoordinates?.[3]) / 2
				});
				mapRef?.current?.setZoom(initialZoom);
			} else if (markers && markers.length > 0) {
				const mapBounds = new window.google.maps.LatLngBounds();
				markers?.forEach(marker => {
					mapBounds.extend({
						lat: marker?.latitude,
						lng: marker?.longitude
					});
				});
				mapRef?.current?.fitBounds(mapBounds);
			}
			isFirstLoad.current = false;
			reverseForceMapFitBounds && reverseForceMapFitBounds();
		}
	}, [markers, isLoaded]);

	return (
		<>
			<div data-testid="googleMap" className={`${className || ''}`}>
				<LoadScriptNext
					googleMapsApiKey={`${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`}
				>
					<GoogleMap
						id="acc-map"
						mapContainerClassName={'w-full h-full'}
						options={mapOptions}
						onLoad={onMapLoad}
						onDragEnd={() => {
							setClickedAccommodation('');
							clearTimeout(mapUpdater);
							mapUpdater = setTimeout(handleOnIdle, 200);
						}}
						onZoomChanged={() => {
							setClickedAccommodation('');
							clearTimeout(mapUpdater);
							mapUpdater = setTimeout(handleOnIdle, 200);
						}}
					>
						{displayedAccommodation()}
						{!isEmpty(markers) &&
							markers?.map(marker => {
								return (
									<OverlayView
										mapPaneName="overlayMouseTarget"
										key={marker['@id']}
										position={{
											lat: marker.latitude,
											lng: marker.longitude
										}}
									>
										<CustomMapMarker
											marker={marker}
											hoveredAccommodation={hoveredAccommodation}
											setClickedAccommodation={setClickedAccommodation}
										/>
									</OverlayView>
								);
							})}
					</GoogleMap>
				</LoadScriptNext>
			</div>
		</>
	);
};

AccommodationsMap.displayName = 'AccommodationsMap';


export default AccommodationsMap;