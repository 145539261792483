import { SvgMapMarker } from 'litto-lib/assets';
import { PopperButton } from 'litto-lib/components/common';
import { priceParser } from 'litto-lib/helpers';
import { useMediaQuery, useOnClickOutside } from 'litto-lib/hooks';
import React from 'react';

import { SelectedAccommodationCard } from '../';
import { IAccommodationRead } from 'services/shop/accommodation/interfaces';


export interface ICustomMapMarkerProps {
	marker: IAccommodationRead;
	hoveredAccommodation?: string;
	setClickedAccommodation: (accId: string) => void;
}

export const CustomMapMarker: React.FC<ICustomMapMarkerProps> = ({
	marker,
	hoveredAccommodation,
	setClickedAccommodation
}) => {
	const isMobile = useMediaQuery('(max-width: 820px)');

	const markerRef = React.useRef(null);

	const [hovered, setHovered] = React.useState(false);
	const [clicked, setClicked] = React.useState(false);

	useOnClickOutside(
		markerRef,
		() => {
			if (clicked) {
				setClickedAccommodation && setClickedAccommodation('');
			}
			setClicked(false);
		},
		[clicked]
	);

	return (
		<>
			{!isMobile ? (
				<PopperButton
					placement="top"
					className={`${
						marker?.code === hoveredAccommodation || hovered
							? ' z-50'
							: 'z-[-1]'
					}`}
					noPadding
					offset={[0, 10]}
					customToggle={
						<div
							className={`cursor-pointer rounded-lg px-4 py-2 ${
								marker?.code === hoveredAccommodation || hovered
									? ' z-50 bg-primary-700'
									: ' bg-white'
							}`}
							onMouseEnter={() => {
								setHovered(true);
							}}
							onMouseLeave={() => {
								setHovered(false);
							}}
						>
							{marker?.averageNightlyPrice ? (
								<p
									className={`font-extrabold ${
										marker?.code === hoveredAccommodation || hovered
											? 'text-white '
											: 'text-gray-600 '
									}`}
								>
									{priceParser(marker?.averageNightlyPrice)}
								</p>
							) : (
								<SvgMapMarker
									className={`${
										marker?.code === hoveredAccommodation || hovered
											? 'text-white'
											: 'text-gray-600'
									}`}
								/>
							)}
						</div>
					}
				>
					<div
						className={`w-11/12 max-w-sm cursor-default rounded bg-white shadow-lg md:w-80`}
					>
						<SelectedAccommodationCard className="w-full" data={marker} />
					</div>
				</PopperButton>
			) : (
				<div
					ref={markerRef}
					className={`cursor-pointer rounded-lg px-4 py-2 ${
						clicked ? ' z-50 bg-primary-700' : ' bg-white'
					}`}
					onClick={() => {
						setClicked(true);
						setTimeout(() => {
							setClickedAccommodation && setClickedAccommodation(marker.code);
						}, 100);
					}}
				>
					{marker?.averageNightlyPrice ? (
						<p
							className={`font-extrabold ${
								clicked ? 'text-white ' : 'text-gray-600 '
							}`}
						>
							{priceParser(marker?.averageNightlyPrice)}
						</p>
					) : (
						<SvgMapMarker
							className={`${clicked ? 'text-white' : 'text-gray-600'}`}
						/>
					)}
				</div>
			)}
		</>
	);
};
